@import "~antd/dist/antd.css";

.app-header .bg-danger,
.app-header .bg-danger .ant-badge i,
.app-header .bg-danger a,
.app-header .bg-dark,
.app-header .bg-dark .ant-badge i,
.app-header .bg-dark a,
.app-header .bg-info,
.app-header .bg-info .ant-badge i,
.app-header .bg-info a,
.app-header .bg-primary,
.app-header .bg-primary .ant-badge i,
.app-header .bg-primary a,
.app-header .bg-success,
.app-header .bg-success .ant-badge i,
.app-header .bg-success a {
  color: #fff !important;
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  border: 0;
  border-radius: 6px;
  background-clip: padding-box;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-layout-sider {
  background-color: #fff !important;
}
.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  background: rgba(14, 146, 69, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  color: #999;
  font-size: 32px;

}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.avatar-uploader > .ant-upload {
  width: 300px;
  height: 300px;
}
